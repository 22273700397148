<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <b-overlay :show="loadMechineType">
                        <div>
                           <b-table-simple small resonsive borderless>
                              <b-tr>
                                  <b-th width="25%">{{ $t('globalTrans.district') }}</b-th>
                                  <b-th width="2%">:</b-th>
                                  <b-td>
                                      {{ getDistrictName(detailsData.district_id) }}
                                  </b-td>
                              </b-tr>
                               <b-tr>
                                  <b-th width="25%">{{ $t('globalTrans.fiscal_year') }}</b-th>
                                  <b-th width="2%">:</b-th>
                                  <b-td>
                                      {{ getFiscalYearName(detailsData.fiscal_year_id) }}
                                  </b-td>
                              </b-tr>
                               <b-tr>
                                  <b-th width="25%">{{ $t('farm_config.season') }}</b-th>
                                  <b-th width="2%">:</b-th>
                                  <b-td>
                                      {{ getSeasonName(detailsData.season_id) }}
                                  </b-td>
                              </b-tr>
                              <b-tr>
                                  <b-th width="25%">{{ $t('farm_config.circular') }}</b-th>
                                  <b-th width="2%">:</b-th>
                                  <b-td>
                                     <span v-if="detailsData.circular">{{ currentLocale === 'en' ? detailsData.circular.circular_name :  detailsData.circular.circular_name_bn }}</span>
                                  </b-td>
                              </b-tr>
                          </b-table-simple>
                          <b-table-simple striped bordered small class="mt-2">
                              <b-thead>
                                  <tr>
                                      <b-th rowspan="2" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                      <b-th rowspan="2"  class="text-center">{{ $t('globalTrans.upazila') }}</b-th>
                                      <template v-if="Object.keys(machineTypeList).length > 0">
                                          <b-th :colspan="machineType.instruments.length" class="text-center" v-for="(machineType, machineTypeIndex) in machineTypeList" :key="'mechine_type-' + machineTypeIndex">
                                              {{ currentLocale === 'en' ? machineType.machine_type : machineType.machine_type_bn }}
                                          </b-th>
                                      </template>
                                  </tr>
                                  <tr class="text-center" v-if="Object.keys(machineTypeList).length > 0">
                                      <template v-for="(machineType, machineTypeIndex) in machineTypeList">
                                          <b-th v-for="(machine, machineIndex) in machineType.instruments" :key="'mechine-name-' + machineTypeIndex + machineIndex" class="text-center">
                                              {{ currentLocale === 'en' ? machine.name : machine.name_bn }}
                                          </b-th>
                                      </template>
                                  </tr>
                              </b-thead>
                              <b-tbody>
                                  <template v-if="Object.keys(detailsData.details).length > 0">
                                      <b-tr v-for="(item, index) in detailsData.details" :key="index">
                                          <b-td  class="text-center">{{ $n(index + 1) }}</b-td>
                                          <b-td class="text-center">{{ getUpazilaName(item.upazilla_id) }}</b-td>
                                          <b-td class="text-center" v-for="(machineType, machineTypeIndex) in item.machine_type_info" :key="'mechine_type-' + machineTypeIndex">
                                            {{machineType.quantity ? $n(machineType.quantity) : ''}}
                                          </b-td>
                                      </b-tr>
                                       <tr :key="index">
                                            <b-td colspan="2"  class="text-right">{{ $t('farm_report.district_total') }}</b-td>
                                              <template v-for="(machineType, machineTypeIndex) in machineTypeList">
                                                  <b-th v-for="(machine, machineIndex) in machineType.instruments" :key="'mechine-name-' + machineTypeIndex + machineIndex" class="text-center">
                                                      {{$n(getTotalMechine(machine.id))}}
                                                  </b-th>
                                            </template>
                                        </tr>
                                  </template>
                                  <template v-else>
                                      <b-tr>
                                          <b-td colspan="4" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                      </b-tr>
                                  </template>
                              </b-tbody>
                          </b-table-simple>
                        </div>
                        </b-overlay>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { machineTypeListApi } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.detailsData = this.item
    this.getMachineTypeList()
  },
  data () {
    return {
      detailsData: {},
      slOffset: 1,
      loadMechineType: false,
      machineTypeList: []
    }
  },
  computed: {
    currentLocale () {
        return this.$i18n.locale
    },
    listData () {
      const objectData = this.item
      return objectData.filter(data => data.id === this.item.id)
    },
    seasonList: function () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Aus' : 'আউশ', text_en: 'Aus', text_bn: 'আউশ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Amon' : 'আমন', text_en: 'Amon', text_bn: 'আমন' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Boro' : 'বোরো', text_en: 'Boro', text_bn: 'বোরো' }
      ]
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      this.getCircularList()
        this.getMachineTypeList()
    }
  },
  methods: {
    getDistrictName (districtId) {
      const obj = this.$store.state.commonObj.districtList.find(el => el.value === parseInt(districtId))
      if (obj !== 'undefinded') {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getUpazilaName (upazilaId) {
      const obj = this.$store.state.commonObj.upazilaList.find(el => el.value === parseInt(upazilaId))
      if (obj !== 'undefinded') {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getFiscalYearName (id) {
      const obj = this.$store.state.commonObj.fiscalYearList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getSeasonName (id) {
      const obj = this.seasonList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    async getMachineTypeList () {
        const params = {
          circular_id: this.detailsData.circular_id
        }
        this.loadMechineType = true
        let result = null
        result = await RestApi.getData(incentiveGrantServiceBaseUrl, machineTypeListApi, params)
        if (result.success) {
            this.machineTypeList = result.data
        } else {
            this.machineTypeList = []
        }
        this.loadMechineType = false
    },
    getTotalMechine (instrumentId) {
      let total = 0
      this.detailsData.allocation_quantities.forEach(element => {
        if (element.instrument_id === instrumentId) {
          total += element.quantity
        }
      })
      return total
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
